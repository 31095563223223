<template>
    <div class="flex flex-col flex-1">
        <SubHeader v-if="showInnerCascaded" :backButton="true" backRedirectionUrl="/field-admin?tab=blockadmin" :setEditModeUnlock="setEditModeUnlock"/>
        <div v-if="loadingState.fetchingBlockDetails && showInnerCascaded" class="flex items-center justify-center flex-1 bg-card-bg rounded-md gap-2">
            <Loader />
        </div>
        <div v-else-if="showInnerCascaded" class="flex p-4 flex-1 flex-col bg-card-bg rounded-md gap-2">
            <BlockDisplay v-if="blockDetails" :blockDetails="blockDetails" v-allow:auth="`block.read`"
                @removeFieldFromBlock="removeFieldFromBlock($event)"
                @updateBlockDetails="updateBlockDetails($event)" @showAddfieldModal="showAddfieldModal"
                :loadingState="loadingState" @deleteBlock="handleDeleteBlock($event)"
                @handleAddFieldToBlock="handleAddFieldToBlock($event)" :allReportsRoles="allReportsRoles"
                @hanldeFieldReorder="hanldeFieldReorder($event)" @changeCaseCreation="changeCaseCreation($event)" @changeblockVisibile="changeblockVisibile($event)"
                @fetchBlockAdminDetails="fetchBlockAdminDetails" :isGlobalLoader="isGlobalLoader" :handleControlGlobalLoader="handleControlGlobalLoader" :showInnerCascaded="showInnerCascaded" :showVerticalGrip=showVerticalGrip
            />
        </div>
        <div v-else class="mx-4 w-full">
            <BlockDisplay v-if="blockDetails" :blockDetails="blockDetails" v-allow:auth="`block.read`"
                @removeFieldFromBlock="removeFieldFromBlock($event)"
                @updateBlockDetails="updateBlockDetails($event)" @showAddfieldModal="showAddfieldModal"
                :loadingState="loadingState" @deleteBlock="handleDeleteBlock($event)"
                @handleAddFieldToBlock="handleAddFieldToBlock($event)" :allReportsRoles="allReportsRoles"
                @hanldeFieldReorder="hanldeFieldReorder($event)" @changeCaseCreation="changeCaseCreation($event)" @changeblockVisibile="changeblockVisibile($event)"
                @fetchBlockAdminDetails="fetchBlockAdminDetails" :isGlobalLoader="isGlobalLoader" :handleControlGlobalLoader="handleControlGlobalLoader" :showInnerCascaded="showInnerCascaded" :showVerticalGrip=showVerticalGrip
                
                />
        </div>

        <ModalConfirm title="Are you sure?"   message="Please confirm you're about to delete the field." ref="confirm-popup"> </ModalConfirm>

        <loader-full v-if="isGlobalLoader.status" :loadingText="isGlobalLoader.message" />
    </div>
</template>

<script>
import SubHeader from "@/components/SubHeader";
import Loader from "@shared/loader";
import LoaderFull from "@/components/loader-full";
import BlockDisplay from "../components/blockDisplay.vue";
import axios from "@/axios";
const ModalConfirm = () => import("@/components/modal-confirm");
import { fetchReportVisibility } from "@/modules/field-editor/services.js";
import { EventBus } from "@/main.js";

export default {
    name: "block-admin-details",
    components: {
        SubHeader,
        Loader,
        BlockDisplay,
        ModalConfirm,
        LoaderFull,
    },
    props: {
        openCascadedOption:{
            type: Boolean,
        },
        blockDetailsData:{
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            queryId: null,
            tenantId: null,
            loadingState: {
                fetchingBlockDetails: false,
            },
            fieldsList: [],
            blockDetails: null,
            allReportsRoles: [],
            isGlobalLoader: {
                status: false,
                message: ''
            },
            showInnerCascaded:true,
            showVerticalGrip:true,
        };
    },
    async mounted() {
        
        if(this.openCascadedOption && this.blockDetailsData){
            this.showVerticalGrip=false;
            this.showInnerCascaded = false;
            this.blockDetails = this.blockDetailsData;
            this.allReportsRoles = await fetchReportVisibility();
        }

        else{ 
            if (!this.$store.getters.getTenantId){
                await this.$store.dispatch("fetchTenantId");
                }
                this.tenantId = this.$store.getters.getTenantId;
                this.queryId = this.$route.params.id || null;
                await this.fetchBlockAdminDetails();
                this.allReportsRoles = await fetchReportVisibility();
        }
    },
    computed: {},
    methods: {
        async fetchBlockAdminDetails() {
            this.loadingState.fetchingBlockDetails = true;
            try {
                if(this.$route.params.action === 'edit'){
                    const lockResult  = await this.setEditmodeLock(this.queryId)
                    if(lockResult == false){
                        this.$router.push({ path: "/field-admin?tab=blockadmin" });
                    }
                }
                let url = `/blocks/${this.queryId}`;
                let { data } = await axios.get(url);
                this.blockDetails = data;
            } catch (error) {
                console.log(error, ">>>>error");
                if(error){
                    this.$toast.error("Failed to load api data")
                    this.$router.push({ path: "/field-admin?tab=blockadmin" });
                }
            }
            this.loadingState.fetchingBlockDetails = false;
        },
        async setEditmodeLock(blockId, option = null) {
            try {
                let payload = { lock_status: true }
                if (option !== null) {
                    payload = { lock_status: false }
                }
                let url = `/blocks/locked/${blockId}`;
                await axios.post(url, payload);
                EventBus.$emit('fetchBlockLists');
                return true;
            } catch (error) {
                this.$toast.error(error.response.data.detail || "Failed to lock this block");
                return false; // Return false when there's an error
            }
        },
        async setEditModeUnlock(){
            if(this.$route.params.action === 'view'){
                return
            }
            let query = this.$route;
            if(query.params.id){
                await this.setEditmodeLock(query.params.id,'backMode')
            }
        },
        async showAddfieldModal() {
        //     this.loadingState.fetchingFieldsLists = true;
        //     this.$refs["block-add-field"].showFieldModal();
        //     this.fieldsList = await this.fetchFieldsList();
        //     this.loadingState.fetchingFieldsLists = false;
        },
        // async fetchFieldsList() {
        //     let payload = {
        //         tenant_id: this.tenantId,
        //         req_offset: 0,
        //         req_limit: 1,
        //     };
        //     let url = `/fields/${this.tenantId}/all`;
        //     let { data } = await axios.get(url, payload);
        //     return data.fields;
        // },
        async handleAddFieldToBlock(data) {
            let { payload, field } = data;
            try {
                let url = `/blocks/${this.blockDetails.id}/field`;
                let { data } = await axios.post(url, payload);
                this.blockDetails.fields.push({ 
                    ...payload, 
                    id: data.block_field_id, 
                    name: field.name, 
                    label: field.label, 
                    ui_label: field.ui_label,
                    type: field.type,
                    is_cascaded: field.is_cascaded, 
                    history: payload.gap_history,
                    use_in_case_creation: false,
                });
                this.$toast.success(data.message || "Field Added ");
            } catch (error) {
                this.$toast.error(error.response.data.detail || "Failed to add field");
            }
        },
        async removeFieldFromBlock(field) {

            if(this.$route.params.action === 'view'){
                return
            }
            const promise = await this.$refs["confirm-popup"].show({
                title: "Are you sure?",
                message: "This Field  will be deleted permanently. Please confirm to continue deleting this field.",
            });
            if (promise) {
                try {
                    let url = `/blocks/${this.blockDetails.id}/field/${field.id}`;
                    let { data } = await axios.delete(url);
                    this.$toast.success(data.message || "Field deleted");
                    this.blockDetails = { ...this.blockDetails, fields: this.blockDetails.fields.filter((el) => el.id !== field.id) };
                } catch (error) {
                    this.$toast.error(error.response.data.detail || "Failed to delete field from block");
                }
            }
            this.$refs["confirm-popup"].close();
        },

        async updateBlockDetails(block) {
            try {
                let payload = {
                    name: block.name,
                    label: block.label,
                    description: block.description,
                    multi: block.multi,
                    block_title: block.block_title,
                    min_count: block.multi ? block.min_count : 1,
                    max_count: block.multi ? block.max_count : 1,
                    // valid_gap: block.valid_gap,
                    // gap_history: block.gap_history,
                };
                let url = `/blocks/${block.id}`;
                let { data } = await axios.put(url, payload);
                this.$toast.success(data.message || "Block updated");
            } catch (error) {
                this.$toast.error(error.response.data.detail || "Failed to update Block");
            }
        },

        async handleDeleteBlock(block) {
            if(this.$route.params.action === 'view'){
                return
            }
            const promise = await this.$refs["confirm-popup"].show({
                title: "Are you sure?",
                message: "This Block  will be deleted permanently. Please confirm to continue deleting this block.",
            });
            if (promise) {
                try {
                    let url = `/blocks/${block.id}`;
                    let { data } = await axios.delete(url);
                    this.$router.push({
                        name: "Fields",
                        query: {
                            tab: "blockadmin",
                        },
                    });
                    this.$toast.success(data.message || "Block Deleted");
                } catch (error) {
                    this.$toast.error(error.response.data.message || "Failed to delete Block");
                }
            }
            this.$refs["confirm-popup"].close();
        },
        async hanldeFieldReorder(e) {
            if(this.$route.params.action === 'view'){
                return
            }
            let field_list = [];
            this.blockDetails.fields.forEach((el, index) => field_list.push({ block_field_id: el.id, order: index + 1 }));
            let payload = {
                field_list,
            };
            if (e.moved) {
                const url = `/blocks/${this.blockDetails.id}/reorder`;
                try {
                    const { data } = await axios.post(url, payload);
                    this.$toast.success(data.message);
                } catch (error) {
                    this.$toast.warning(error.response.data.detail || "Failed to reorder Field!");
                }
                // this.$toast.success("Section Reordered");
            }
        },
        async changeCaseCreation(field) {
            try {
                const { data } = await axios.patch(`/blocks/case-form/field/${field.id}`, {
                    state: field.use_in_case_creation || false,
                });
                if (data) this.$toast.success(field.use_in_case_creation ? `Using in case creation` : `Removed from case creation`)
            } catch (error) {
                this.$toast.error(error.response.data.detail || `Something went wrong`)
            }
        },
        async changeblockVisibile(field) {
            this.handleControlGlobalLoader(true, 'Updating Field attributes...')
            if (field?.id === "") {
                    return;
                }
                let field_id=field?.id
                let block_visibility = field?.block_visibility  
            try {
                const { data } = await axios.patch(`/blocks/block-visibility/field/${field_id}`, {
                    block_visibility: block_visibility,
                });
                if (data) this.$toast.success(data.message || 'Field block visibility marked for Case form')
                if(this.queryId ){
                    await this.fetchBlockAdminDetails()
                }
                this.handleControlGlobalLoader(false)
            } catch (error) {
                this.handleControlGlobalLoader(false)
                this.$toast.error(error.response.data.detail || `Something went wrong`)
            }
        },
        handleControlGlobalLoader(status, message){
            this.isGlobalLoader = {
                status,
                message:message || 'Loading...'
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
